import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Button,
} from "@material-tailwind/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { addItem } from "../../../context/actions/postaction";
import logo from "../../images/logo.png";
export function Login({ onLogin }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     onLogin({ username: email, password });
  //   };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const itemData = {
      email,
      password,
    };

    try {
      const response = await dispatch(addItem(itemData));
      if (response.status === 200) {
        onLogin(true);
        setEmail("");
        setPassword("");
        Navigate(`/`);
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-center h-screen  "
    >
      <Card className="w-80 ">
        <CardHeader
          color="blue"
          variant="gradient"
          className="mb-4 grid h-28 place-items-center  "
        >
          <Typography
            className="text-center flex justify-center"
            variant="h4"
            color="white"
          >
            <img style={{ width: 100, height: "100%" }} src={logo} alt="" />
          </Typography>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <Input
            color="blue"
            className="font-tajawal"
            label="Email"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            color="blue"
            className="font-tajawal"
            label="Password"
            size="lg"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </CardBody>
        <CardFooter className="pt-0">
          <Button
            color="blue"
            className="font-tajawal "
            fullWidth
            type="submit"
          >
            تسجيل الدخول
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}
