import React from "react";

// @material-tailwind/react
import {
  Button,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
  CardBody,
} from "@material-tailwind/react";
import Reports from "../reports";
import { useSelector } from "react-redux";

function KpiCard({ title }) {
  const { item } = useSelector((state) => state.item);

  return (
    <Card
      dir="rtl"
      color="blue"
      className="shadow-sm border border-gray-200 !rounded-lg "
    >
      <CardBody className="p-4">
        <div className="flex justify-between items-center">
          <Typography className="!font-medium !text-xs text-white font-tajawal">
            {title}
          </Typography>
          {/* <div className="flex items-center gap-1">
            {icon}
            <Typography color={color} className="font-medium !text-xs">
              {percentage}
            </Typography>
          </div> */}
        </div>
        <Typography
          color="white"
          className="mt-1 font-bold text-2xl font-tajawal"
        >
          {item.hesap} د.ع
        </Typography>
      </CardBody>
    </Card>
  );
}

const data = [
  {
    title: "الأرباح",
  },
];

function Home() {
  return (
    <section className="container mx-auto  px-2">
      <div className="mt-6 mb-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 items-center md:gap-2.5 gap-4">
        {data.map((props, key) => (
          <KpiCard key={key} {...props} />
        ))}
      </div>
      <Reports />
    </section>
  );
}

export default Home;
