import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";
import "./styles.css";

export default function Designer() {
  const { item } = useSelector((state) => state.item);
  const projeler = item.projeler;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 p-4 ">
      {projeler.map((item, key) => {
        const image = item.images;
        return (
          <Card
            key={key}
            className="w-full max-w-full card-container black-foto "
          >
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper w-full font-tajawal"
            >
              {image.map((img, key) => (
                <SwiperSlide key={key}>
                  <img
                    src={`https://designer.alayadi.site/project_imgs/${img.photo}`}
                    alt=""
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <CardBody>
              <Typography
                variant="h5"
                color="blue-gray"
                className="mb-2 font-tajawal text-white"
              >
                {item.title}
              </Typography>
              <p
                className="font-tajawal text-white"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></p>
            </CardBody>
          </Card>
        );
      })}
    </div>
  );
}
