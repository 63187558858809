import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
const TABLE_HEAD = ["المبلغ", "الحالة", "التاريخ"];

function Reports() {
  const { item } = useSelector((state) => state.item);
  const data = item.kullanici.islemler;
  // ترتيب البيانات حسب التاريخ الأحدث
  const sortedData = data.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // إدارة حالة الصفحة الحالية
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  // حساب البيانات المعروضة بناءً على الصفحة الحالية
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // أشهر تبدأ من 0 لذا نضيف 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // دالة للتنقل بين الصفحات
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(sortedData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  return (
    <Card className="bg-white ">
      <CardBody className="overflow-scroll px-0">
        <table
          dir="rtl"
          className="mt-4 w-full min-w-max table-auto text-right "
        >
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="black"
                    className="font-normal leading-none opacity-70 font-tajawal "
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => {
              const isLast = index === currentData.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-100";

              return (
                <tr key={item.id}>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="black"
                        className="font-normal  font-tajawal"
                      >
                        {item.fiyat}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="w-max ">
                      <Chip
                        className="text-gray-600 font-tajawal "
                        variant="ghost"
                        size="sm"
                        value={item.islem_tipi == 0 ? "عمولة" : "سحب"}
                        color={item.islem_tipi == 0 ? "green" : "red"}
                      />
                    </div>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="black"
                      className="font-normal  font-tajawal"
                    >
                      {formatDate(item.created_at)}
                    </Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography
          variant="small"
          color="blue"
          className="font-normal  font-tajawal"
        >
          صفحة {currentPage} من {Math.ceil(sortedData.length / itemsPerPage)}
        </Typography>
        <div className="flex gap-2">
          <Button
            className=" font-tajawal"
            color="blue"
            variant="outlined"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            السابق
          </Button>
          <Button
            className=" font-tajawal"
            color="blue"
            variant="outlined"
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(sortedData.length / itemsPerPage)
            }
          >
            التالي
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

export default Reports;
