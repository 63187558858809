import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";
import { useCopyToClipboard } from "usehooks-ts";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

export default function Code() {
  const [value, copy] = useCopyToClipboard();
  const [copied, setCopied] = React.useState(false);
  const { item } = useSelector((state) => state.item);
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex justify-center items-center bg-blue-500 w-64 h-16 text-center rounded-2xl shadow-md shadow-blue-900">
        <div className="flex items-center gap-x-4 ">
          <Typography className="font-tajawal" color="white" variant="lead">
            {item.kullanici.middleman_key}
          </Typography>
          <IconButton
            color="white"
            onMouseLeave={() => setCopied(false)}
            onClick={() => {
              copy(`${item.kullanici.middleman_key}`);
              setCopied(true);
            }}
          >
            {copied ? (
              <CheckIcon className="h-5 w-5 text-blue-500" />
            ) : (
              <DocumentDuplicateIcon className="h-5 w-5 text-blue-500" />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
}
