import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { reducerAddItem } from "../reducer/reducerPostData";
import { reducerGetNews } from "../reducer/reducerGetData";

const rootReducer = combineReducers({
  item: reducerAddItem,
  news: reducerGetNews,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
