import React from "react";
import {
  Card,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Alert,
  Input,
  Drawer,
  Navbar,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  CubeTransparentIcon,
  MagnifyingGlassIcon,
  Bars3Icon,
  XMarkIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import Img from "../images/profile.png";
import logo from "../images/logo.png";
import { useSelector } from "react-redux";

const profileMenuItems = [
  {
    label: "تسجيل الخروج",
    icon: PowerIcon,
    link: 2,
  },
];

function ProfileMenu({ onLogin }) {
  const { item } = useSelector((state) => state.item);
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="black"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto font-tajawal"
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="profile"
            className="border border-black p-0.5"
            src={Img}
          />
          {item.kullanici.name}
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform bg-w font-tajawal${
              isMenuOpen ? "rotate-180 font-tajawal" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, link }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              color="black"
              key={label}
              onClick={() => {
                link === 1 ? navigate("/reports") : onLogin(false);
              }}
              className={`flex items-center gap-2 rounded font-tajawal ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10 font-tajawal"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 font-tajawal ${
                  isLastItem ? "text-red-500" : ""
                }`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal font-tajawal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default function NavBar({ onLogin }) {
  const [openNav, setOpenNav] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isLargeScreen, setIsLargeScreen] = React.useState(
    window.innerWidth >= 960
  );

  React.useEffect(() => {
    const handleWindowResize = () => setIsLargeScreen(window.innerWidth >= 960);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const sidebarContent = (
    <>
      <div className="mb-2 flex items-center gap-4 p-4   ">
        <Typography className="font-tajawal" variant="h5" color="white">
          <img style={{ width: 150, height: "100%" }} src={logo} alt="" />
        </Typography>
      </div>
      <List>
        <Link to={"/"}>
          <ListItem className=" justify-end text-black font-tajawal">
            الرئيسية
            <ListItemPrefix className=" pl-4">
              <PresentationChartBarIcon className="h-5 w-5" />
            </ListItemPrefix>
            {/* <ListItemSuffix>
              <Chip
                value="14"
                size="sm"
                variant="ghost"
                color="blue-gray"
                className="rounded-full"
              />
            </ListItemSuffix> */}
          </ListItem>
        </Link>
        <Link to={"/code"}>
          <ListItem className=" justify-end text-black font-tajawal">
            الكود
            <ListItemPrefix className=" pl-4">
              <InboxIcon className="h-5 w-5" />
            </ListItemPrefix>
          </ListItem>
        </Link>
      </List>
    </>
  );

  return (
    <>
      <Navbar className="mx-auto max-w-full px-6 py-3 rounded-none flex justify-between ">
        <div className="flex items-center gap-2 ">
          <ProfileMenu onLogin={onLogin} />
        </div>
        {!isLargeScreen && (
          <IconButton
            color="black"
            variant="text"
            size="lg"
            onClick={openDrawer}
          >
            {isDrawerOpen ? (
              <XMarkIcon className="h-8 w-8 stroke-2" />
            ) : (
              <Bars3Icon className="h-8 w-8 stroke-2" />
            )}
          </IconButton>
        )}
      </Navbar>
      {isLargeScreen ? (
        <Card className="fixed top-0 right-0 h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 bg-blue-gray-50">
          {sidebarContent}
        </Card>
      ) : (
        <Drawer
          open={isDrawerOpen}
          onClose={closeDrawer}
          className="fixed top-0 right-0 bg-red-50/0"
          placement="right"
        >
          <Card
            color="transparent"
            shadow={false}
            className="h-[calc(100vh-2rem)] w-full p-4 bg-blue-gray-50"
          >
            {sidebarContent}
          </Card>
        </Drawer>
      )}
    </>
  );
}
