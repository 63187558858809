import { Typography } from "@material-tailwind/react";

export function Footer() {
  return (
    <footer className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-100 py-6 text-center md:justify-center">
      <Typography color="blue" className="font-normal font-tajawal ">
        &copy; 2024{" "}
        <a href="https://alayadids.com/">Alayadi Digital Solutions</a>
      </Typography>
    </footer>
  );
}
